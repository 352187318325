import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import HomeContactComponent from "../components/HomeContactComponent"
import TarsadalmiCardHolder from "../components/TarsadalmiCardHolder"
import TarsadalmiOrgsComponent from "../components/TarsadalmiOrgsComponent"

const TarsadalmiPage = props => {
  const { i18n } = useTranslation()
  const language = i18n.language
  const mainPageData = props.data.sanityHomePage
  const thisPageData = props.data.sanityTarsadalmiPage
  const pageTitle = thisPageData._rawTitle[language]
  const seoImage = getImage(thisPageData.image)?.images.fallback.src
  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <Container>
        <Seo
          title={`Cseriti - ${pageTitle}`}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={
            language === "hu" ? thisPageData.image : thisPageData.image_en
          }
          slogan={thisPageData._rawTitle[language]}
          singleImage={true}
        />
      </Container>
      <TarsadalmiCardHolder cards={thisPageData.cards} />
      <TarsadalmiOrgsComponent organisations={thisPageData.organisations} />
      <HomeContactComponent intro={mainPageData._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query TarsadalmiPageQuery {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityTarsadalmiPage {
      _rawTitle
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image_en {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      organisations {
        name
        url
        logo {
          asset {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
      cards {
        _rawBoxDescription
        _rawTitle
        icon {
          asset {
            gatsbyImageData(width: 100, layout: CONSTRAINED)
            extension
            path
          }
        }
      }
    }
  }
`
export default TarsadalmiPage
